import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Logo from 'images/oima-logo.svg';
import { Feedback, SubmitButton } from 'shared/components';
import { EmailField, Form, FormField, PasswordField } from 'shared/components/Formik';
import { usePost } from 'shared/hooks/useApi';
import { createQueryParams, getParameterByName } from 'shared/utils/commonUtils';
import { languages } from 'shared/constants/languages';

export const LoginForm = () => {

    const lang = getParameterByName('lang');
    const langQueryParams = createQueryParams({ lang }, { omitEmptyValues: true });
    const [{ isPending } ,makePreLoginPost] = usePost('/api/login');
    const [isPasswordLogin, setIsPasswordLogin] = useState(false);
    const [hasLoginError, setHasLoginError] = useState(false);
    const [prevUsername, setPrevUsername] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const oldSchoolLogin = async (username, password) => {
        const formData = new FormData();
        setIsLoggingIn(true);
        formData.append('_username', username);
        formData.append('_password', password);
        try {
            const resp = await fetch('/login_check?reAuthDialog=1', {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
            });
            if (resp.ok) {
                const json = await resp.json();
                location.href = json?.redirectUrl ?? '/home?role=999';
                return;
            }

            setIsLoggingIn(false);
            setHasLoginError(true);
        } catch (e) {
            setIsLoggingIn(false);
            setHasLoginError(true);
        }
    };

    return (
        <article className="c-login u-align-item-center">
            <header className="u-text-center u-margin-bottom-small u-margin-bottom@medium">
                <figure className="c-logo">
                    <img src={Logo} width="100%" alt="Oima-logo"/>
                </figure>
                <h1 className="c-heading-headline">
                    {_trans('login_form.heading')}
                </h1>
            </header>
            <div className="u-padding-top-small o-pin o-pin--left o-pin--top u-1/1 u-text-center">
                {languages.map((language, key) => (
                    <Fragment key={key}>
                        <a href={`/login?lang=${language.locale}`}
                            title={language.title}
                            lang={language.lang}
                            key={key}
                            className="u-padding-horizontal-small"
                        >
                            {language.label}
                        </a>
                        {(key >= 0 && key < languages.length - 1) && <span className="u-muted">{' | '}</span>}
                    </Fragment>
                ))}
            </div>
            <p className="u-text-center">
                {_trans('login_form.do_you_need_st_account', {}, 'login')}{' '}
                <br/>
                <a href={`/register?${langQueryParams}`} className="u-font-weight-bold">
                    {_trans('login_form.create_account', {}, 'login')}
                </a>
            </p>
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                onSubmit={({ username, password }, actions) => {
                    if (isPasswordLogin && prevUsername !== '' && prevUsername === username) {
                        oldSchoolLogin(username, password);
                        actions.setTouched({}, false);
                    } else {
                        makePreLoginPost({ username }).then((json) => {
                            if (json.data.useVismaConnect) {
                                location.href = json.data.redirectUrl;
                            } else {
                                setIsPasswordLogin(true);
                                // Resetoidaan virheet ettei heti huudella salasanan puutteesta
                                actions.setTouched({}, false);
                                document.getElementById('password').focus();
                            }
                        });
                    }
                    setPrevUsername(username);
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().required(_trans('validation.required')).email(_trans('validation.email')),
                    password: isPasswordLogin ? Yup.string().required(_trans('validation.required')) : Yup.string(),
                })}
            >
                {() =>
                    <Form alignFields="vertical" modifierClass="c-login__form">
                        {hasLoginError && (
                            <Feedback type="error" modifierClass="u-margin-vertical-small">
                                {_trans('Väärä tunnus/salasana', {}, 'common')}
                            </Feedback>
                        )}
                        <FormField name="username" label={_trans('login_form.email.label', {}, 'login')}>
                            {/*eslint-disable-next-line*/}
                            <EmailField className="u-1/1" autoFocus autoComplete="off" autoCorrect="off"
                                autoCapitalize="off"/>
                        </FormField>
                        <div
                            aria-hidden={!isPasswordLogin}
                            className={classNames('u-margin-bottom u-padding-bottom-small u-margin-bottom-large@medium u-padding-bottom-none@medium', {
                                'u-animation-slide-in-down': isPasswordLogin,
                                'u-display-none': !isPasswordLogin,
                            })}
                        >
                            <FormField name="password" label={_trans('login_form.password.label', {}, 'login')}>
                                <PasswordField
                                    className="u-1/1"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    tabIndex={isPasswordLogin ? 0 : -1}
                                />
                            </FormField>
                        </div>
                        <SubmitButton size="large" modifierClass="u-1/1" isPending={isLoggingIn || isPending}
                            mdIcon={!isPasswordLogin && 'arrow_forward'} iconAfterText>
                            {isPasswordLogin
                                ? _trans('Kirjaudu salasanalla', {}, 'common')
                                : _trans('Jatka', {}, 'common')}
                        </SubmitButton>
                    </Form>
                }
            </Formik>
            <div className="u-margin-top-small u-text-center">
                <a href={`/recovery?${langQueryParams}`}>{_trans('Unohditko salasanasi?', {}, 'common')}</a>
            </div>
        </article>
    );
};
